import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Typography, Box, Paper, Avatar, ToggleButton, ToggleButtonGroup, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CustomButton from '../components/Button';
import { useAuth } from '../hooks/useAuth';
import { triggerSignInWithEmailLink } from '../api/accountService';
import SecureStorage from '../services/secureStorage';
import { kLoggingInEmail } from '../config/constants';
import VmModal from '../components/VmModal';
import { Close } from '@mui/icons-material';
import { ValidateEmail } from '../utils/general';
// const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === "dev";
const isDevEnvironment = true;  // TODO: CHANGE THIS BACK

const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [loginMethod, setLoginMethod] = useState<'email' | 'username'>(isDevEnvironment ? 'username' : 'email');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null); // Error state for validation and API errors
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [openTermsDialog, setOpenTermsDialog] = useState(false);
    const { login } = useAuth(); // Use the useAuth hook
    const navigate = useNavigate();

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError(null); // Clear any previous errors
        localStorage.clear();

        if (!acceptedTerms) {
            setOpenTermsDialog(true);
            return;
        }

        try {
            if (loginMethod === 'username') {
                // Username/Password login flow
                if (!username || !password) {
                    setError('Please provide both username and password');
                    setLoading(false);
                    return;
                }

                await login(username, password); // Use useAuth's login method
                navigate('/summary/dashboard'); // Redirect to dashboard
            } else if (loginMethod === 'email') {
                // Email link login flow
                if (!ValidateEmail(email)) {
                    setError('Please provide a valid email address');
                    setLoading(false);
                    return;
                }

                // Store the email in SecureStorage before triggering the email link
                const secureStorage = SecureStorage.getInstance();
                secureStorage.setItem(kLoggingInEmail, email); // Store the email

                const response = await triggerSignInWithEmailLink(email);
                if (response) {
                    alert('Login link sent to your email');
                } else {
                    setError('Failed to send login link. Please try again.');
                }
            }
        } catch (err) {
            setError('Login failed. Please try again.');
            console.error('Login error:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleLoginMethodChange = (event: React.MouseEvent<HTMLElement>, newMethod: 'email' | 'username') => {
        if (newMethod !== null) {
            setLoginMethod(newMethod);
        }
    };

    return (
        <>
            <Container component="main" maxWidth="xs">
                <Paper elevation={3} sx={{ padding: 3, marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Login
                    </Typography>

                    {/* Toggle between login methods */}
                    {
                        isDevEnvironment && (
                            <ToggleButtonGroup
                                value={loginMethod}
                                exclusive
                                onChange={handleLoginMethodChange}
                                aria-label="login method"
                                sx={{ marginBottom: 2 }}
                            >
                                <ToggleButton value="username" aria-label="username and password">
                                    Username & Password
                                </ToggleButton>
                                <ToggleButton value="email" aria-label="email login">
                                    Email Login
                                </ToggleButton>
                            </ToggleButtonGroup>
                        )
                    }

                    {/* Display any error */}
                    {error && (
                        <Typography variant="body2" color="error" sx={{ mb: 2 }}>
                            {error}
                        </Typography>
                    )}

                    {/* Form section */}
                    <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
                        {loginMethod === 'username' ? (
                            <>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Username"
                                    name="username"
                                    autoComplete="username"
                                    autoFocus
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    disabled={loading}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    disabled={loading}
                                />
                                <Box className="flex items-center justify-center gap-2 mt-2">
                                    <Checkbox value="remember" color="primary" checked={acceptedTerms} onChange={(e) => setAcceptedTerms(e.target.checked)} sx={{ marginLeft: -1 }} />
                                    <p>I have read and accepted <a className="text-blue-500 underline cursor-pointer" onClick={() => setOpenTermsDialog(true)}>Terms & Conditions</a> for website access.</p>
                                </Box>
                                <CustomButton
                                    type="submit"
                                    fullWidth
                                    label={loading ? 'Logging in...' : 'Login with Username'}
                                    disabled={loading || !username || !password || !acceptedTerms}
                                    sx={{ mt: 3, mb: 2 }}
                                />
                            </>

                        ) : (
                            <>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled={loading}
                                />
                                <Box className="flex items-center justify-center gap-2 mt-2">
                                    <Checkbox value="remember" color="primary" checked={acceptedTerms} onChange={(e) => setAcceptedTerms(e.target.checked)} sx={{ marginLeft: -1 }} />
                                    <p>I have read and accepted <a className="text-blue-500 underline cursor-pointer" onClick={() => setOpenTermsDialog(true)}>Terms & Conditions</a> for website access.</p>
                                </Box>
                                <CustomButton
                                    type="submit"
                                    fullWidth
                                    label={loading ? 'Sending...' : 'Send Login Link'}
                                    disabled={loading || !email || !acceptedTerms}
                                    sx={{ mt: 3, mb: 2 }}
                                />
                            </>
                        )}
                    </Box>

                    {
                        loginMethod === 'username' && <Box className="flex items-center justify-center mb-4">
                            <p className="text-sm text-gray-500 cursor-pointer" onClick={() => navigate('/forgot-password')}>Forgot Password?</p>
                        </Box>
                    }

                </Paper>
            </Container>

            <VmModal open={openTermsDialog} onClose={() => setOpenTermsDialog(false)}>
                <Box className="h-full flex flex-col overflow-y-auto">
                    <div className="p-4 text-sans flex-1 flex flex-col gap-8">
                        <Box className="flex items-center justify-between">
                            <h1 className="text-4xl font-bold text-gray-900">WollemAI | Privacy Policy</h1>
                            <IconButton onClick={() => setOpenTermsDialog(false)}><Close /></IconButton>
                        </Box>

                        <div className="">
                            <h2 className="text-2xl font-bold mb-4 mt-6 text-blue-600">Introduction</h2>
                            <div className="tc-content w-richtext">
                                <p>Welcome to WollemAI.<br />&zwj;<br />WollemAI operates <a href="https://www.wollemai.com/"
                                    className="font-bold">https://wollem.ai</a> (hereinafter referred to as the “Service”).<br />&zwj;<br />Your
                                    privacy is important to us.<br />&zwj;<br />Our Privacy Policy governs your visit to <a
                                        href="https://www.wollemai.com/" className="font-bold">https://wollem.ai,</a> and explains how we collect,
                                    safeguard and use information that results from your use of our Service.<br />&zwj;<br />You can visit our
                                    website without choosing to reveal any personal information about yourself.<br />&zwj;<br />We use your data
                                    to provide and improve the Service. By using the Service, you agree to the collection and use of
                                    information in accordance with this policy.<br /><br />Our Terms and Conditions govern all use of our
                                    Service and together with the Privacy Policy constitutes your agreement with us.<br /><br />If you have
                                    concerns about this privacy statement, or any of these purposes, please contact us at <a
                                        href="mailto:support@wollemai.com" className="font-bold">support@wollemai.com</a> we will always respect a
                                    request by you to stop processing your personal information (subject to our legal obligation).</p>
                            </div>
                            <h2 className="text-2xl font-bold mb-4 mt-6 text-blue-600">Bases for processing of the data</h2>
                            <div className="tc-content w-richtext">
                                <p>When a user connects to this website, they are providing personal information. That information may
                                    include data such as your IP address, name, email address, and/or other data information. By providing
                                    this information, the user consents to their information being collected, used, managed and stored by
                                    WollemAI.<br /><br />This information is used by WollemAI by way of example.</p>
                                <ul role="list" className="list-disc list-inside">
                                    <li>To monitor the usage of our Service</li>
                                    <li>To support and improve the services offered by this website</li>
                                    <li>To provide you with news, and special information</li>
                                    <li>We may use your Personal Data to contact you with newsletters, marketing or promotional materials
                                        and other information that may be of interest to you. You may opt out of receiving any or all of
                                        these communications from us by so requesting it by email or other means</li>
                                    <li>To ensure compliance with the Terms of Use and applicable law. This may include the development of
                                        tools and algorithms that help this website ensure the confidentiality of the personal data it
                                        collects.</li>
                                    <li>To fulfill any other purpose for which you provide it WollemAI, in its capacity as data controller,
                                        will be able to access the data provided by users directly, as well as data relating to the use and
                                        traceability of the Services they use. In the case of digital, for example: access information, type
                                        and frequency of use.</li>
                                </ul>
                                <p>The user expressly authorizes WollemAI to use their personal information in order to improve the proposal
                                    of the present and future Services to be offered, for which purposes it may process data that are
                                    suitable for establishing certain profiles for commercial or advertising purposes.</p>
                            </div>
                            <h2 className="text-2xl font-bold mb-4 mt-6 text-blue-600">Security of your data</h2>
                            <div className="tc-content w-richtext">
                                <p>WollemAI will take the relevant protection measures in accordance with its security policy and current
                                    regulations to prevent the adulteration, loss, consultation or unauthorized treatment of the information
                                    it uses in its capacity as data controller, if applicable by virtue of the data it accesses. <br />The
                                    security of your data is important to us. However, no method of transmission over the Internet or method
                                    of electronic storage is 100% secure. While we strive to use processes and good practices to protect
                                    your Personal Data, we cannot guarantee its absolute security. <br />WollemAI undertakes to keep
                                    confidential the data provided and accessed, unless requested by a competent authority or when it is
                                    legally obliged to disclose it. Our employees are bound by the confidentiality of the information they
                                    access.</p>
                            </div>
                            <h2 className="text-2xl font-bold mb-4 mt-6 text-blue-600">Location Data</h2>
                            <div className="tc-content w-richtext">
                                <p>The personal data provided by users within the framework of the use of the Services will be hosted on
                                    servers owned or contracted by WollemAI and located in Australia.<br /><br />If you are located outside
                                    Australia and choose to provide information to us, please note that we transfer the data, including
                                    Personal Data, to Australia and process it there.<br /><br />Your consent to this Privacy Policy implies
                                    consent to any transfer required under this policy, and by providing information directly to WollemAI,
                                    you consent to that transfer as well.<br /><br />Your information, including Personal Data, may be
                                    transferred to – and maintained on – computers located outside of your state, province, country, or
                                    other governmental jurisdiction where the data protection laws may differ from those of your
                                    jurisdiction.</p>
                            </div>
                            <h2 className="text-2xl font-bold mb-4 mt-6 text-blue-600">Disclosure of data</h2>
                            <div className="tc-content w-richtext">
                                <p>We may disclose personal information that we collect, or you provide, in cases like these:</p>
                                <ul role="list" className="list-disc list-inside last">
                                    <li>To our subsidiaries and affiliates</li>
                                    <li>To contractors, service providers, and other third parties we use to support our business</li>
                                    <li>If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data
                                        may be transferred.</li>
                                    <li>If required to do so by law or in response to valid requests by public authorities.</li>
                                    <li>With your consent in any other cases.</li>
                                </ul>
                            </div>
                            <h2 className="text-2xl font-bold mb-4 mt-6 text-blue-600">Links to Other Sites</h2>
                            <div className="tc-content w-richtext">
                                <p>The main WollemAI website contains links to third-party sites located outside of WollemAI.<br />WollemAI
                                    has no control and is not responsible for the privacy practices or the content of these websites.<br />If
                                    you click a third-party link, you will be directed to that third-party’s site. We strongly advise you to
                                    review the Privacy Policies published by these third-party sites.</p>
                            </div>
                            <h2 className="text-2xl font-bold mb-4 mt-6 text-blue-600">Data retention time</h2>
                            <div className="tc-content w-richtext">
                                <p>We will retain your Personal Data only for as long as is necessary for the purposes set out in this
                                    Privacy Policy.<br /><br />We will retain and use your Personal Data to the extent necessary to comply with
                                    our legal obligations.<br /><br />We will also retain Usage Data for internal analysis purposes.</p>
                            </div>
                            <h2 className="text-2xl font-bold mb-4 mt-6 text-blue-600">General Data Protection Regulation (GDPR)</h2>
                            <div className="tc-content w-richtext">
                                <p>If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data
                                    protection rights, covered by GDPR.<br /><br />If you submit a request to stop transferring your personal
                                    information, we will stop making such transfers. <br /><br />We may update our Privacy Policy from time to
                                    time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                            </div>
                            <h2 className="text-2xl font-bold mb-4 mt-6 text-blue-600">Acceptance and consent</h2>
                            <div className="tc-content w-richtext">
                                <p>The user declares to have been informed and understood about the conditions on protection of personal
                                    data, accepting and consenting to the treatment of these by WollemAI.<br />When browsing WollemAI,
                                    non-identifiable data may be collected, which may include IP addresses, geographic location
                                    (approximately), a record of how the services and sites are used, and other data that cannot be used to
                                    identify the user.</p>
                            </div>
                            <h2 className="text-2xl font-bold mb-4 mt-6 text-blue-600">Privacy Question</h2>
                            <div className="tc-content w-richtext">
                                <p>You have the right to access, rectify or delete your personal data.<br /><br />If you have any questions
                                    about this Privacy Policy, please contact us by email: &nbsp;<a href="mailto:support@wollemai.com"
                                        className="font-bold">support@wollemai.com.</a></p>
                            </div>
                            <div className="tc-create">This Privacy Policy was created on 01/04/2024 and last edited on April 25, 2024.</div>
                        </div>
                    </div>

                    <Box className="flex items-center gap-2 p-4 sticky bottom-0 bg-white border-t border-gray-200">
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" checked={acceptedTerms} onChange={(e) => {
                                setAcceptedTerms(e.target.checked);
                                setTimeout(() => {
                                    if (e.target.checked === true) setOpenTermsDialog(false)
                                }, 200);
                            }} sx={{ marginLeft: -1 }} />}
                            label="I have read and accepted Terms & Conditions."
                        />
                    </Box>

                </Box>
            </VmModal>
        </>
    );

};

export default Login;
