import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Login from './pages/Login';
import AuthGuard from './auth-guard/AuthGuard'
import LoginByEmailLink from './pages/LoginByEmailLink';

// Pages
import SummaryDashboardPage from './pages/SummaryDashboard';
import SummaryPage from './pages/Summary';
import OwnersPage from './pages/Owners';
import AssetsPage from './pages/Assets';

// router
import { Outlet } from 'react-router-dom';
import ForgotPassword from './pages/ForgotPassword';


const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* Public route for login */}
        <Route path="/login" element={<Login />} />
        {/* Public route for handling login via email link */}
        <Route path="/account/handle_login" element={<LoginByEmailLink />} /> {/* Unprotected Route */}
        <Route path="/forgot-password" element={<ForgotPassword />} />

        {/* Private routes protected by AuthGuard */}
        <Route
          path="/"
          element={
            <AuthGuard>
              <Outlet />
            </AuthGuard>
          }
        >
          <Route path="summary/dashboard" element={<SummaryDashboardPage />} />
          <Route path="summary" element={<SummaryPage />} />
          <Route path="owners" element={<OwnersPage />} />
          <Route path="assets" element={<AssetsPage />} />
          <Route path="" element={<Navigate to="/summary/dashboard" />} />
        </Route>

        {/* Redirect any unknown routes to login */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
