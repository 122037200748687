import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import CompareFilters from '../../CompareFilters'
import { FilterContext } from '../../../context/filter-bar-context'
import PRGradientStatsCircle from '../../PrGradientStatsCircle'
import { ClimateScenario, EntityLevel, PRRiskLevel, SearchType } from '../../../utils/options'
import { Box, IconButton, Skeleton, Typography } from '@mui/material'
import PrDecileTable from '../../PRDecileTable'
import { exportFile, getOrganizationPRData } from '../../../api/explorerService'
import SecureStorage from '../../../services/secureStorage'
import { FilterLevelTwo, FilterLevelThree } from '../../summary/filter-section'
import { APIErrorHandler, GenerateExportUrl, GetToastProps, ParseChangeValues } from '../../../utils/general'
import { FilterStoreContext } from '../../../context/filter-store-context'
import VmModal from '../../VmModal'
import { Close } from '@mui/icons-material'
import ClimateChangeToggleBar from '../../climate_change_toggle_bar'
import { useSnackbar } from 'notistack'

const SummaryPRAcuteDroughtSection = observer(({ levelTwo, levelThree }: { levelTwo: FilterLevelTwo, levelThree: FilterLevelThree }) => {
  const { reportPeriod, setAllowedCompareToYears } = useContext(FilterContext)
  const { enqueueSnackbar } = useSnackbar();
  const filterStore = useContext(FilterStoreContext);
  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [showClimateChangeModal, setShowClimateChangeModal] = useState(false)
  const [climateChangeScenario, setClimateChangeScenario] = useState<ClimateScenario>(ClimateScenario.CURRENT)

  const [loading, setLoading] = useState(true)
  const [climateRiskScoreLoading, setClimateRiskScoreLoading] = useState(false)

  const [data, setData] = useState<any>(null)
  const [climateChangeData, setClimateChangeData] = useState<any>(null)

  let lvl2QueryParam = levelTwo === FilterLevelTwo.PHYSICAL_RISK_ACUTE ? "acute_risks" :
    levelTwo === FilterLevelTwo.PHYSICAL_RISK_CHRONIC ? "chronic_risks" : "";
  let lvl3QueryParam = levelThree === FilterLevelThree.PHYSICAL_RISK_ACUTE_DROUGHT ? "drought"
    : levelThree === FilterLevelThree.PHYSICAL_RISK_ACUTE_FLOOD ? "flood"
      : levelThree === FilterLevelThree.PHYSICAL_RISK_CHRONIC_TEMPERATURE ? "temperature"
        : levelThree === FilterLevelThree.PHYSICAL_RISK_CHRONIC_RAINFALL ? "rainfall" : ""

  const refreshData = (fy: number = reportPeriod) => {
    if (!lvl2QueryParam || !lvl3QueryParam) return;

    const loadingFn = showClimateChangeModal ? setClimateRiskScoreLoading : setLoading;

    loadingFn(true)
    getOrganizationPRData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: showClimateChangeModal ? climateChangeScenario : ClimateScenario.CURRENT,
      reportPeriod: "" + fy,
      entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      // @ts-ignore
    }, filterStore.entityLevel, lvl2QueryParam, lvl3QueryParam)
      .then((data) => {
        if (!data.data || data.data.length === 0) return;

        const fyData = data.data.find((fyData: any) => +fyData.FY === fy)
        if (!fyData) return;

        if (showClimateChangeModal) {
          setClimateChangeData(fyData.data)
        } else {
          setData(fyData.data)
        }
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), GetToastProps(error)))
      .finally(() => loadingFn(false))
  }

  const handleExportData = () => {
    exportFile(
      GenerateExportUrl("lu", lvl2QueryParam, lvl3QueryParam),
      {
        force_refresh: false,
        include_trajectory: false,
        climateScenario: showClimateChangeModal ? climateChangeScenario : ClimateScenario.CURRENT,
        reportPeriod: "" + reportPeriod,
        entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      }, "Land Use and Removals - Summary.pdf")
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), GetToastProps(error)))
  }

  useEffect(() => {
    if (showClimateChangeModal === false && climateChangeData) return;
    refreshData()
  }, [levelTwo, levelThree, showClimateChangeModal, climateChangeScenario])

  return (<>
    <CompareFilters
      handleClickClimateChange={() => setShowClimateChangeModal(true)}
      hideCompareTo={true}
      onClickExport={handleExportData}
      defaultBenchmark={true}
      defaultTarget={true}
      showExport={filterStore.entityLevel === EntityLevel.ASSET}
    />

    <ChartSection loading={loading} data={data} levelThree={levelThree} />

    <VmModal open={showClimateChangeModal} onClose={() => setShowClimateChangeModal(false)}>
      <Box className="flex flex-col gap-4">
        <Box className="flex items-center justify-between">
          <Typography variant="h6">Stress Test</Typography>
          <IconButton onClick={() => setShowClimateChangeModal(false)}>
            <Close />
          </IconButton>
        </Box>
        <ClimateChangeToggleBar selectedClimateScenario={climateChangeScenario} setSelectedClimateScenario={setClimateChangeScenario} />
        <ChartSection loading={climateRiskScoreLoading} data={climateChangeData} levelThree={levelThree} />
      </Box>
    </VmModal>
  </>
  )
})

const ChartSection = observer(({ loading, data, levelThree }: { loading: boolean, data: any, levelThree: FilterLevelThree }) => {
  return (<Box className='gap-4 grid grid-cols-3 items-center justify-center mt-6 w-[90%] mx-auto'>
    <Box className='w-full max-w-[450px] aspect-square'>
      {
        loading
          ? <>
            <Box className='relative'>
              <Skeleton variant="circular" width={450} height={450} />
              <Box className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-2 items-center'>
                <Skeleton variant="text" width={100} height={40} />
                <Skeleton variant="text" width={150} height={40} />
              </Box>
            </Box>
          </>
          : data && <PRGradientStatsCircle
            title={
              levelThree === FilterLevelThree.PHYSICAL_RISK_ACUTE_DROUGHT ? "Average Drought Risk"
                : levelThree === FilterLevelThree.PHYSICAL_RISK_ACUTE_FLOOD ? "Average Flood Risk"
                  : levelThree === FilterLevelThree.PHYSICAL_RISK_CHRONIC_TEMPERATURE ? "Average Temperature Risk"
                    : levelThree === FilterLevelThree.PHYSICAL_RISK_CHRONIC_RAINFALL ? "Average Rainfall Risk"
                      : ""
            }
            value={data.Data.Total_Risk.value}
            changeAmount={ParseChangeValues(data.Data.Total_Risk.change)}
            riskLevel={PRRiskLevel.MEDIUM}
          />
      }
    </Box>
    <Box className='col-span-2'>
      {
        loading
          ? <Skeleton variant="rectangular" width={750} height={450} className='rounded-lg' />
          : data && <PrDecileTable data={data.Data.RiskCategory} isHorizontal={false} lineInfo={{
            average: data.Data.average.value,
            benchmark: data.Data.benchmark.value,
            target: data.Data.target.value,
          }} />
      }
    </Box>
  </Box>)
})


export default SummaryPRAcuteDroughtSection