import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Button, ButtonGroup, useTheme } from '@mui/material'
import { ArrowForwardIos, ArrowUpward, FileUploadOutlined } from '@mui/icons-material'
import VmDropdown from './VmDropdown'
import VmCheckBox from './VmCheckBox'
import { GenerateCompareToYearOptions } from '../utils/general';
import { useContext } from 'react';
import { FilterContext } from '../context/filter-bar-context';
import { EntityLevel } from '../utils/options'
import { FilterStoreContext } from '../context/filter-store-context';

interface ModeToggleButtonOption {
  icon?: React.ReactNode,
  label: string,
}

const CompareFilters = observer(({
  handleClickClimateChange,
  changeReportPeriodCallBack,
  onClickExport,
  allowNoCompareToYears = true,
  showRow1 = true,
  hideCompareTo = false,
  modeToggleButtonOptions = [],
  modeToggleSelected,
  onModeToggleButtonClick,
  defaultBenchmark = true,
  defaultTarget = true,
  showBenchmarkToggle = true,
  showTargetToggle = true,
  showStressTestToggle = true,
  showExport = true
}: {
  handleClickClimateChange?: () => void,
  changeReportPeriodCallBack?: (value: number) => void,
  onClickExport?: () => void,
  allowNoCompareToYears?: boolean,
  showRow1?: boolean,
  hideCompareTo?: boolean,
  modeToggleButtonOptions?: ModeToggleButtonOption[],
  modeToggleSelected?: string,
  onModeToggleButtonClick?: (value: string) => void,
  defaultBenchmark?: boolean,
  defaultTarget?: boolean,
  showBenchmarkToggle?: boolean,
  showTargetToggle?: boolean,
  showStressTestToggle?: boolean,
  showExport?: boolean,
}) => {
  const {
    reportPeriod, setReportPeriod,
    compareToYears, setCompareToYears,
    showBenchmark, setShowBenchmark,
    showTarget, setShowTarget,
    allowedCompareToYears, setAllowedCompareToYears,
  } = useContext(FilterContext)
  const filterStore = useContext(FilterStoreContext);
  const compareToYearOptions = GenerateCompareToYearOptions(allowedCompareToYears, false, allowNoCompareToYears);
  const theme = useTheme();

  const forcedDisableBenchmarkTarget = true;

  useEffect(() => {
    setCompareToYears(0)
    // if (allowedCompareToYears > 0) {
    //   setCompareToYears(compareToYearOptions[compareToYearOptions.length - 1].value)
    // }
  }, [allowedCompareToYears])

  const handleChangeReportPeriod = (value: number) => {
    changeReportPeriodCallBack && changeReportPeriodCallBack(value)
    setReportPeriod(value)
  }

  useEffect(() => {
    if (!showBenchmarkToggle) setShowBenchmark(false)
    else if (defaultBenchmark !== undefined) setShowBenchmark(defaultBenchmark)

    if (!showTargetToggle) setShowTarget(false)
    else if (defaultTarget !== undefined) setShowTarget(defaultTarget)
  }, [])

  return (
    <Box className="flex flex-col gap-2">
      {/* TOP ROW */}
      {
        showRow1 && <Box className="flex gap-4">
          <Box className="border rounded-md p-4 py-2 bg-gray-100 flex gap-4 items-center flex-1">
            <p>Compare to</p>
            <VmCheckBox label={"Benchmark"} checked={showBenchmark || false} disabled={!showBenchmarkToggle || forcedDisableBenchmarkTarget} onChange={(checked: boolean) => setShowBenchmark && setShowBenchmark(checked)} />
            <VmCheckBox label={"Target"} checked={showTarget || false} disabled={!showTargetToggle || forcedDisableBenchmarkTarget} onChange={(checked: boolean) => setShowTarget && setShowTarget(checked)} />
          </Box>

          {
            showStressTestToggle && <Button variant="outlined" sx={{ borderRadius: '8px' }} onClick={handleClickClimateChange}>
              Stress Test
              <ArrowForwardIos sx={{ fontSize: 16, ml: 1, mr: -0.5 }} />
            </Button>
          }
        </Box>
      }

      {/* BOTTOM ROW */}
      <Box className="border rounded-md p-4 py-2 bg-gray-100 flex justify-between ">
        <Box className="flex gap-4 items-center">
          <p className='font-bold'>Report Period</p>
          <VmDropdown label="" value={reportPeriod} onChange={handleChangeReportPeriod} options={filterStore.fyOptions} />
          {
            !hideCompareTo && <>
              <p className=''>Compare to</p>
              <VmDropdown disabled={allowedCompareToYears <= 0} label="" value={compareToYears} onChange={setCompareToYears} options={compareToYearOptions} />
            </>
          }
          {/* <p className=''>& Future</p>
        <VmDropdown label="" value={compareToFuture} onChange={setCompareToFuture} options={compareToFutureOptions} /> */}

          {/* <p>Turn out</p>
        <Box className="flex gap-1 items-center bg-[#ebf1fd] px-2 py-1 rounded-md">
          <p>xx%</p>
          <ArrowUpward sx={{ fontSize: 20 }} color='error' />
        </Box> */}
        </Box>

        <Box className="flex gap-4 items-center">
          {
            modeToggleButtonOptions && modeToggleButtonOptions.length > 0 && <ButtonGroup variant="contained" aria-label="Basic button group">
              {
                modeToggleButtonOptions.map((option, index) => (
                  <Button key={index}
                    variant={modeToggleSelected === option.label ? "contained" : "outlined"}
                    sx={{ borderRadius: '8px' }}
                    onClick={() => { onModeToggleButtonClick && onModeToggleButtonClick(option.label) }}
                  >
                    {option.icon}
                    <p>{option.label}</p>
                  </Button>
                ))
              }
            </ButtonGroup>
          }

          {/* onClickExport && <Box> */}
          {
            showExport && onClickExport && <Button variant="outlined" sx={{ borderRadius: '6px', backgroundColor: theme.palette.primary.main, color: "white" }} onClick={onClickExport}>
              <FileUploadOutlined sx={{ fontSize: 24, mr: 1 }} />
              <p>Export</p>
            </Button>
          }
        </Box>
      </Box>
    </Box>
  )
})

export default CompareFilters
