import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { exportFile, getOrganizationAEData } from '../../../api/explorerService'
import { ClimateScenario, EntityLevel, SearchType } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import CompareFilters from '../../CompareFilters'
import moment from 'moment'
import { APIErrorHandler, FormatFYChartTitle, FormatKeyToLabel, GenerateExportUrl, GenerateFYOptions, GetMinYAxisValue, GetScenarioName, GetToastProps } from '../../../utils/general'
import { FilterContext } from '../../../context/filter-bar-context'
import VmModal from '../../VmModal'
import { Box, IconButton, Skeleton, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { defaultDataKeyColours, lerpColor, RandomRGB } from '../../../utils/colour'
import SimpleLineChart from '../../charts/simple-line-chart'
import ClimateChangeToggleBar from '../../climate_change_toggle_bar'
import { useSnackbar } from 'notistack'
import { FilterStoreContext } from '../../../context/filter-store-context'
import BannerText from '../../BannerText'
interface ProcessedGridData {
  childrenCount: number;
  name: string;
  description?: string;
  bulletpoints?: string;
  value?: number;
  Total?: number;
  metricTitle: string;
  color: string;
  children: ProcessedGridData[];
}

const colorSets = [
  { start: "#1f1a60", end: "#6598ff" },
  { start: "#846528", end: "#dda119" },
  { start: "#306524", end: "#2c781c" },
]

const SummaryGHGTypesSection = observer(({ }: {}) => {
  const { reportPeriod, compareToYears, setAllowedCompareToYears, } = useContext(FilterContext)
  const filterStore = useContext(FilterStoreContext);
  const { enqueueSnackbar } = useSnackbar();

  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')

  const [maximumLayers, setMaximumLayers] = useState(0);
  const [processedData, setProcessedData] = useState<ProcessedGridData[]>([]);
  const [totalGHG, setTotalGHG] = useState<number | null>(null);

  const [openInformationModal, setOpenInformationModal] = useState(false);
  const [informationModalCallStack, setInformationModalCallStack] = useState<any[]>([]);
  const [informationModalData, setInformationModalData] = useState<any>(null);
  const [informationModalTitles, setInformationModalTitles] = useState<string[]>([]);

  // CLIMATE CHANGE MODAL
  const [showClimateChangeModal, setShowClimateChangeModal] = useState(false);
  const [selectedClimateScenario, setSelectedClimateScenario] = useState(ClimateScenario.CURRENT);
  // const [climateChangeModalData, setClimateChangeModalData] = useState<any>(null);

  const [climateChangeLineChartData, setClimateChangeLineChartData] = useState<any>([]);
  const [climateChangeLineChartFyOptions, setClimateChangeLineChartFyOptions] = useState<number[]>([]);

  const [loadingMainGrid, setLoadingMainGrid] = useState(false);
  const [loadingInformationModal, setLoadingInformationModal] = useState(false);
  const [loadingClimateChangeLineChart, setLoadingClimateChangeLineChart] = useState(false);
  const [climateChangeOnlyShowTotal, setClimateChangeOnlyShowTotal] = useState<boolean>(false)

  const dataKeyColours = useRef<{ [key: string]: string }>(defaultDataKeyColours)

  const getLayers = (data: any) => {
    let maximumLayers = 0;

    const hasNextLayer = (layer: any, parentLayer: any) => {
      const currentLayer = parentLayer + 1;

      if (layer.children && layer.children.length > 0) {
        layer.children.forEach((child: any) => {
          hasNextLayer(child, currentLayer)
        })
      }

      if (currentLayer > maximumLayers) {
        maximumLayers = currentLayer
        if (layer.bulletpoints) {
          maximumLayers = currentLayer + 1;
        }
      }

      return;
    }

    Object.keys(data).forEach((key: any) => {
      hasNextLayer(data[key], 0)
    })

    return maximumLayers;
  }

  const processData = (data: any) => {
    let tempProcessedData: any = [];

    const countChildren = (data: any, layer: number) => {
      let count = 0;
      if (data.children && data.children.length > 0) {
        data.children.forEach((child: any) => {
          count += countChildren(child, layer + 1)
        })
        data.childrenCount = count;
      } else if (layer !== 0) {
        count += 1;
      }

      data.childrenCount = count;
      return count;
    }

    Object
      .keys(data)
      // .filter((key: any) => !["FY", "GrossEmission", "NetEmissions"].includes(key))
      .filter((key: any) => key.toLowerCase().startsWith("scope"))
      .forEach((key: any) => {
        tempProcessedData.push({
          ...data[key],
          name: FormatKeyToLabel(key),
          childrenCount: countChildren(data[key], 0),
        })
      })

    return tempProcessedData;
  }


  const refreshData = (fy: number = reportPeriod) => {
    setLoadingMainGrid(true);
    const currentReportPeriod = +JSON.stringify(fy)
    getOrganizationAEData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + currentReportPeriod,
      entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
    }, filterStore.entityLevel, "emissions", "ghg", "current_year_vs_scenario")
      .then((data) => {
        let rawData = data.data;
        if ("data" in rawData) rawData = rawData.data;

        rawData = Array.isArray(rawData) ? rawData : [rawData]

        const currentFyData = rawData.find((data: any) => +data.FY === currentReportPeriod);

        if (currentFyData && Object.keys(currentFyData).length > 0) {
          setMaximumLayers(getLayers(currentFyData))
          setProcessedData(processData(currentFyData))
          setTotalGHG((currentFyData.GrossEmission && currentFyData.GrossEmission.value) ? +currentFyData.GrossEmission.value : null)
        } else {
          setMaximumLayers(0)
          setProcessedData([])
          setTotalGHG(null)
        }
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), GetToastProps(error)))
      .finally(() => setLoadingMainGrid(false))
  }

  const handleClickClimateChange = () => {
    setShowClimateChangeModal(true);
    setLoadingClimateChangeLineChart(true);

    let climateChangeChartData: any[] = [];
    let climateChangeFYOptions: any[] = [];

    Promise.all(
      [ClimateScenario.CURRENT, ClimateScenario.DISORDERLY].map((climateScenario: ClimateScenario) => {
        return getOrganizationAEData({
          force_refresh: false,
          include_trajectory: true,
          climateScenario: climateScenario,
          reportPeriod: "" + reportPeriod,
          entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
        }, filterStore.entityLevel, "emissions", "ghg", "current_year_vs_scenario")
          .then((data) => {
            let rawData = data.data;
            if ("data" in rawData) rawData = rawData.data;

            rawData = rawData.sort((a: any, b: any) => +a.FY - +b.FY)

            if (climateChangeFYOptions.length === 0) {
              climateChangeFYOptions = rawData.map((data: any) => data.FY)
            }

            if (rawData.length === 0) return;

            const dataKeys = Object.keys(rawData[0])
              .filter((key: string) => !["FY"].includes(key));

            for (const key of dataKeys) {
              if (!dataKeyColours.current[key]) {
                dataKeyColours.current[key] = RandomRGB();
              }
            }

            dataKeys.forEach((key: any) => {
              climateChangeChartData.push({
                name: `${FormatKeyToLabel(key)} - ${GetScenarioName(climateScenario)}`,
                type: 'line',
                data: rawData.map((data: any) => ({
                  ...data[key],
                  fy: data.FY,
                  climateScenario: climateScenario,
                })),
                climateScenario: climateScenario,
                itemStyle: {
                  color: dataKeyColours.current[key] || RandomRGB()
                },
                isTotal: key === "GrossEmission",
              })
            })
          })
      })
    )
      .then(() => {
        climateChangeChartData.sort((a: any, b: any) => a.name.localeCompare(b.name))

        setClimateChangeLineChartData(climateChangeChartData)
        setClimateChangeLineChartFyOptions(climateChangeFYOptions)
      })
      .finally(() => setLoadingClimateChangeLineChart(false))
  }

  const handleExportData = () => {
    exportFile(
      GenerateExportUrl("ae", "emissions", "ghg", "/current_year_vs_scenario"),
      {
        force_refresh: false,
        include_trajectory: false,
        climateScenario: showClimateChangeModal ? selectedClimateScenario : ClimateScenario.CURRENT,
        reportPeriod: "" + reportPeriod,
        entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      }, "Absolute Emission - GHG Types and Sources.pdf")
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), GetToastProps(error)))
  }

  const getInformationModalData = (callStack: any[] = informationModalCallStack, fy: number = reportPeriod) => {
    setLoadingInformationModal(true);
    getOrganizationAEData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: showClimateChangeModal ? selectedClimateScenario : ClimateScenario.CURRENT,
      reportPeriod: "" + fy,
      entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      metricTitle: callStack.map((layer: any) => layer.name).join(".")
    }, filterStore.entityLevel, "emissions", "ghg", "current_year_vs_previous")
      .then((data) => {
        let rawData = data.data
        if ("data" in rawData) rawData = rawData.data

        setAllowedCompareToYears(fy - rawData.data[0].FY)
        setInformationModalData(rawData.data.map((data: any) => {
          let tempData: any = {};
          Object.keys(data)
            .filter((key: any) => !["FY", "assets"].includes(key))
            .forEach((key: any) => {
              tempData["value"] = (data[key] && data[key].value) ? (+data[key].value.toFixed(2)) : 0;
              tempData["FY"] = +data.FY;
              tempData["assets"] = data.assets || []
            })

          tempData["itemStyle"] = {
            color: +data.FY !== fy ? '#adccff' : '#0061ff', // Color of the symbols
            lineStyle: {
              color: +data.FY !== fy ? '#adccff' : '#0061ff', // Color of the line
            },
          };

          return tempData;
        }))
        setInformationModalTitles(rawData.data.map((data: any) => data.FY))
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), GetToastProps(error)))
      .finally(() => {
        setLoadingInformationModal(false);
      })
  }

  const onClickHandler = (callStack: any[]) => {
    setInformationModalCallStack(callStack)
    setOpenInformationModal(true)
    getInformationModalData(callStack)
  }

  useEffect(() => {
    refreshData()
  }, [])

  return (<>
    <CompareFilters
      handleClickClimateChange={handleClickClimateChange}
      changeReportPeriodCallBack={refreshData}
      hideCompareTo={true}
      onClickExport={handleExportData}
      showBenchmarkToggle={false}
      showExport={filterStore.entityLevel === EntityLevel.ASSET}
      showTargetToggle={false}
    />

    <Box className='mt-4 flex flex-col gap-2'>
      {
        loadingMainGrid
          ? <>
            <Skeleton variant="text" width={400} height={40} animation="wave" className='rounded-lg' />
            <Skeleton variant="text" width={150} height={40} animation="wave" className='rounded-lg' />
            <Box className='grid grid-cols-4 gap-1'>
              <Skeleton variant="rectangular" height={150} animation="wave" className='rounded-lg' />
              <Skeleton variant="rectangular" height={150} animation="wave" className='rounded-lg' />
              <Skeleton variant="rectangular" height={150} animation="wave" className='rounded-lg' />
              <Skeleton variant="rectangular" height={150} animation="wave" className='rounded-lg' />
              <Skeleton variant="rectangular" height={150} animation="wave" className='rounded-lg' />
              <Skeleton variant="rectangular" height={150} animation="wave" className='rounded-lg' />
              <Skeleton variant="rectangular" height={150} animation="wave" className='rounded-lg' />
              <Skeleton variant="rectangular" height={150} animation="wave" className='rounded-lg' />
            </Box>
          </>
          : <>
            <Box className='flex gap-4 items-center'>
              <p className='text-[1.2rem] font-bold'>Total GHG Emissions</p>
              <p className='text-[1.6rem] font-bold'>{totalGHG ? (+totalGHG.toFixed()).toLocaleString() : "--"}</p>
            </Box>
            <p className=' text-gray-500'>tCO2e</p>

            <Box className='grid gap-2' sx={{ gridTemplateColumns: `repeat(${maximumLayers + 1}, 1fr)` }}>
              {processedData.map((data: any, scopeIndex: number) => (
                <GHGGridItem gridLayer={data} currentLayer={0} maximumLayers={maximumLayers} fillInCellsCount={maximumLayers - 1} colorSet={colorSets[scopeIndex]} onClick={onClickHandler} callStack={[data]} />
              ))}
            </Box>
          </>
      }
    </Box>

    {/* LINE SERIES INFORMATION MODAL */}
    <VmModal open={openInformationModal} onClose={() => setOpenInformationModal(false)} >
      <Box className='flex flex-col gap-4'>
        <Box className='flex items-center justify-between gap-2'>
          <Typography variant='h6'>{informationModalCallStack.map((layer: any) => layer.name).join(" > ")}</Typography>
          <IconButton onClick={() => setOpenInformationModal(false)}>
            <Close />
          </IconButton>
        </Box>

        <CompareFilters handleClickClimateChange={() => { }} changeReportPeriodCallBack={(fy: number) => { getInformationModalData(informationModalCallStack, fy) }} showRow1={false} />

        <Box>
          {
            loadingInformationModal
              ? <BannerText text={"Loading..."} />
              : <SimpleLineChart
                tooltipFormatter={(value: any) => {
                  // console.log(value)
                  const data = value.data
                  let tempString = `<b>FY: ${data.FY}</b><br>`
                  if (data.value !== null) tempString += `Value: ${(+data.value).toLocaleString()}<br>`
                  const assets = (data.assets || []).map((asset: any) => `${asset.name}: ${asset.value}}<br>`)

                  return tempString + assets.join("<br>")
                }}
                series={
                  informationModalData && [
                    {
                      name: '',
                      type: 'line',
                      data: informationModalData
                        .filter((data: any) => +data.FY >= reportPeriod - compareToYears && +data.FY <= reportPeriod)
                        .map((item: any) => ({
                          ...item,
                          value: item.value || 0,
                          symbol: 'roundRect',
                          symbolSize: 20
                        })),
                    },
                  ]
                }
                titles={informationModalTitles && informationModalTitles
                  .filter((title: any) => +title >= reportPeriod - compareToYears && +title <= reportPeriod)
                  .map((title: any) => FormatFYChartTitle(title))
                } />
          }
        </Box>
      </Box>
    </VmModal>

    {/* CLIMATE CHANGE MODAL */}
    <VmModal open={showClimateChangeModal} onClose={() => { setShowClimateChangeModal(false) }} >
      <Box className='flex justify-between items-center'>
        <Typography variant='h6'>Stress Test</Typography>
        <IconButton onClick={() => setShowClimateChangeModal(false)}>
          <Close />
        </IconButton>
      </Box>

      <ClimateChangeToggleBar
        selectedClimateScenario={selectedClimateScenario}
        setSelectedClimateScenario={setSelectedClimateScenario}
        showTotalOnly={climateChangeOnlyShowTotal}
        setShowTotalOnly={setClimateChangeOnlyShowTotal}
      />

      <SimpleLineChart
        key={`climate-change-modal-line-chart-${selectedClimateScenario}-${climateChangeOnlyShowTotal}`}
        yAxisTitle={"tCO2e"}
        animation={false}
        loading={loadingClimateChangeLineChart}
        titles={climateChangeLineChartFyOptions.filter((fy: number) => fy >= reportPeriod).map(fy => FormatFYChartTitle(fy))}
        // minYAxisValue={GetMinYAxisValue(climateChangeLineChartData.filter((data: any) => climateChangeOnlyShowTotal || data.isTotal === true))}
        series={climateChangeLineChartData
          .filter((data: any) => climateChangeOnlyShowTotal || data.isTotal === true)
          .map((data: any) => ({
            ...data,
            type: "line",
            symbol: selectedClimateScenario === data.climateScenario ? 'roundRect' : 'triangle',
            symbolSize: 20,
            lineStyle: {
              width: 2,
              type: selectedClimateScenario === data.climateScenario ? "solid" : "dashed",
              color: data.color
            },
            data: data.data
              .filter((value: any) => +value.fy >= reportPeriod)
              .map((value: any) => {
                return {
                  ...value,
                  itemStyle: {
                    color: value.color,
                    opacity: selectedClimateScenario === value.climateScenario ? 1 : 0.4,
                  },
                  value: value.value,
                }
              })
          }))}
      />
    </VmModal>
  </>
  )
})


const GHGGridItem = ({ gridLayer, currentLayer, fillInCellsCount, colorSet, maximumLayers, onClick, callStack }: { gridLayer: ProcessedGridData, currentLayer: number, fillInCellsCount: number, colorSet: { start: string, end: string }, maximumLayers: number, onClick: (callStack: any[]) => void, callStack: any[] }) => {
  // console.log(gridLayer, fillInCellsCount)
  const bgColor = lerpColor(colorSet.start, colorSet.end, currentLayer / maximumLayers);

  return (
    <>
      <Box
        key={gridLayer.name}
        className='p-3 rounded-lg flex justify-between hover:brightness-125 cursor-pointer transition-all duration-100'
        sx={{
          gridRow: `span ${gridLayer.childrenCount} / span ${gridLayer.childrenCount}`,
          backgroundColor: bgColor,
          color: "#fff"
        }}
        onClick={() => { onClick(callStack) }}
      >
        <Box className='mt-auto'>
          <p className='font-bold text-[1.2rem]'>{gridLayer.name}</p>
          <p>{gridLayer.description}</p>
        </Box>
        <Box>
          {
            (gridLayer.value ?? gridLayer.Total)
              ? <p className='font-bold text-[1.2rem]'>{(+(gridLayer.value ?? gridLayer.Total ?? 0).toFixed()).toLocaleString()}</p>
              : <p className='font-bold text-[1.2rem] opacity-30 whitespace-nowrap'>--</p>
          }
        </Box>
      </Box>

      {/* {
        gridLayer.bulletpoints && <Box className='h-full w-full flex items-start justify-start border border-gray-300 rounded-lg p-4' >
          {
            gridLayer.bulletpoints && <ul className='list-disc list-inside'>
              {gridLayer.bulletpoints.split(",").map((bulletpoint: string) => (
                <li>{bulletpoint}</li>
              ))}
            </ul>
          }
        </Box>
      } */}

      {
        currentLayer === 2 &&
        <Box className='h-full w-full flex items-start justify-start border border-gray-300 rounded-lg p-4' >
          <ul className='list-disc list-inside'>
            {
              getBulletPoints(gridLayer.name, gridLayer.metricTitle).map((bulletpoint: string) => (
                <li>{bulletpoint}</li>
              ))
            }

          </ul>
        </Box>
      }

      {
        fillInCellsCount > 0 && ((!gridLayer.children || gridLayer.children.length === 0))
          ? <>
            {
              Array.from({ length: fillInCellsCount }).map((_, index) => (
                <Box key={index} className='h-full w-full flex items-center justify-center border border-gray-300 text-gray-400 rounded-lg' >
                  <p>--</p>
                </Box>
              ))
            }
            <Box className='h-full w-full flex items-start justify-start border border-gray-300 rounded-lg p-4' >
              <ul className='list-disc list-inside'>
                {
                  getBulletPoints(gridLayer.name, gridLayer.metricTitle).map((bulletpoint: string) => (
                    <li>{bulletpoint}</li>
                  ))
                }
              </ul>
            </Box>
          </>

          : gridLayer.children && gridLayer.children.length > 0 && gridLayer.children.map((child: any) => (
            <GHGGridItem gridLayer={child} currentLayer={currentLayer + 1} fillInCellsCount={fillInCellsCount - 1} colorSet={colorSet} maximumLayers={maximumLayers} onClick={onClick} callStack={[...callStack, child]} />
          ))
      }
    </>
  )
}

const getBulletPoints = (title: string, metricTitle: string) => {
  title = (title ?? "").trim().toLowerCase();
  metricTitle = (metricTitle ?? "").trim().toLowerCase();

  if (title.startsWith("scope")) {
    if (title.endsWith("3")) return ['Fertiliser', 'Herbicides / Pesticides', "Lime", "Urea", "Purchased feed", "Livestock purchases", "Fuel"]
    if (title.endsWith("2")) return ["Purchased electricity"]
  }

  if (metricTitle.includes("scope1")) {
    switch (title) {
      case "nitrous oxide":
        return ["Fertiliser", "Crop residues", "Atmospheric deposition", "Leaching & run-off", "Animal waste", "Urine & dung"]
      case "carbon dioxide":
        return ["Fuel", "Lime", "Urea", "Transport"]
      case "methane":
        return ["Electric fermentation", "Manure management", "Field burning"]
    }
  }

  return []
}

export default SummaryGHGTypesSection
