import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { exportFile, getOrganizationAEData } from '../../../api/explorerService'
import { ClimateScenario, EntityLevel, SearchType } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import SectorCompareBarChart from '../../charts/sector-compare-bar-chart'
import CompareFilters from '../../CompareFilters'
import { APIErrorHandler, FormatFYChartTitle, FormatKeyToLabel, GenerateExportUrl, GenerateFYOptions, GetMinYAxisValue, GetScenarioName, GetToastProps, ParseChangeValues } from '../../../utils/general'
import { FilterContext } from '../../../context/filter-bar-context'
import SimpleBarChart from '../../charts/simple-bar-chart'
import { defaultDataKeyColours, lerpColor, RandomRGB } from '../../../utils/colour'
import VmModal from '../../VmModal'
import { Box, IconButton, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import ClimateChangeToggleBar from '../../climate_change_toggle_bar'
import { useSnackbar } from 'notistack'
import { FilterStoreContext } from '../../../context/filter-store-context'
import SimpleLineChart from '../../charts/simple-line-chart'


const SummeryAESectorSection = ({ }: {}) => {
  const { reportPeriod, compareToYears, setAllowedCompareToYears, } = useContext(FilterContext)
  const { enqueueSnackbar } = useSnackbar();
  const filterStore = useContext(FilterStoreContext);

  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])
  const [benchmarkValue, setBenchmarkValue] = useState<number | null>(null)
  const [targetValue, setTargetValue] = useState<number | null>(null)
  const [averageValue, setAverageValue] = useState<number | null>(null)
  const [fyOptions, setFyOptions] = useState<number[]>([])

  const [unit, setUnit] = useState<string>("")

  const [loading, setLoading] = useState<boolean>(false)
  const [loadingStressTestModal, setLoadingStressTestModal] = useState<boolean>(false)

  // climate change modal
  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const [selectedClimateScenario, setSelectedClimateScenario] = useState<ClimateScenario>(ClimateScenario.CURRENT)
  const [climateChangeModalFyOptions, setClimateChangeModalFyOptions] = useState<number[]>([])
  const [climateChangeModalLineSeriesDataWithFy, setClimateChangeModalLineSeriesDataWithFy] = useState<any[]>([])
  const [climateChangeOnlyShowTotal, setClimateChangeOnlyShowTotal] = useState<boolean>(false)

  const dataKeyColours = useRef<{ [key: string]: string }>(defaultDataKeyColours)

  const refreshData = (fy: number = reportPeriod) => {
    setLoading(true)
    getOrganizationAEData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + fy,
      entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
    }, filterStore.entityLevel, "emissions", "sector")
      .then((data) => {
        setUnit(data.data.unit)

        let rawData = data.data
        if ("data" in rawData) rawData = rawData.data

        if (rawData.length === 0) {
          setChartData([])
          return
        }

        let tempBarChartData: any[] = []

        rawData = rawData.sort((a: any, b: any) => +a.FY - +b.FY)

        if (rawData.length > 0) {
          let dataKeys = Object.keys(rawData[0]).filter((key: string) => !["FY"].includes(key))

          const selectedFYData = rawData.find((data: any) => +data.FY === fy)
          // const markLineData: any[] = []

          if (selectedFYData) {
            const benchmarkValue = selectedFYData["Net Emissions"].benchmark
            const targetValue = selectedFYData["Net Emissions"].target
            const averageValue = selectedFYData["Net Emissions"].average

            if (targetValue !== 0) setTargetValue(targetValue)
            if (benchmarkValue !== 0) setBenchmarkValue(benchmarkValue)
            if (averageValue !== 0) setAverageValue(averageValue)
          }

          // NON-SUMMARY-DATA, SECTORS
          dataKeys
            .filter((key: string) => !["Net Emissions", "Removals"].includes(key))
            .forEach((key: string, keyIndex: number) => {
              const mainColor = lerpColor("#1d62f4", "#002c8c", keyIndex / (dataKeys.length - 1))

              tempBarChartData.push({
                name: FormatKeyToLabel(key),
                ...(showClimateChangeModal ? {
                  type: 'line',
                  symbol: "roundRect",
                  symbolSize: 16,
                } : {
                  stack: 'stack1',
                  barWidth: 80,
                  type: 'bar',
                }),
                data: rawData.map((data: any) => ({
                  ...data[key],
                  fy: data.FY
                })),
                itemStyle: {
                  color: mainColor
                },
                // markLine: {
                //   data: (keyIndex === 0) ? markLineData : []
                // },
              })

              tempBarChartData.push({
                name: `${FormatKeyToLabel(key)} (Removals)`,
                ...(showClimateChangeModal ? {
                  type: 'line',
                  symbol: "roundRect",
                  symbolSize: 16,
                } : {
                  stack: 'stack1',
                  barWidth: 80,
                  type: 'bar'
                }),
                data: rawData.map((data: any) => ({
                  ...data[key],
                  value: (data[key] && data[key].removals) ? (showClimateChangeModal ? 1 : -1) * (data[key].removals) : 0,
                  fy: data.FY
                })),
                itemStyle: {
                  color: "#FFFFFF00",
                  borderColor: mainColor,
                  borderWidth: 1,
                  borderType: "dashed"
                }
              })
            })

          // HIDDEN UNIVERSAL REMOVALS

          // tempBarChartData.push({
          //   name: "Carbon Removals",
          //   type: 'bar',
          //   stack: 'stack1',
          //   // data: rawData.map((data: any) => -1 * 99999),
          //   data: rawData.map((data: any) => ({
          //     ...data["Removals"],
          //     value: (data["Removals"] && data["Removals"].value) ? -1 * (data["Removals"].value) : 0,
          //     fy: data.FY
          //   })),
          //   itemStyle: {
          //     color: "white",
          //     borderColor: "#0061ff",
          //     borderWidth: 1,
          //     borderType: "dashed"
          //   }
          // })

          if (!showClimateChangeModal) {
            tempBarChartData.push({
              name: "Net Emissions",
              type: 'line',
              data: rawData.map((data: any) => ({
                ...data["Net Emissions"],
                fy: data.FY
              })),
              symbol: "rect",
              symbolSize: [80, 5],
              lineStyle: {
                width: 1,
                color: "#0061ff"
              },
              itemStyle: {
                color: "#15F5BA"
              }
            })
          }

          // console.log(tempBarChartData)

          setChartData(tempBarChartData)

          const firstFY = rawData[0].FY
          setAllowedCompareToYears((+fy) - (+firstFY))
        }

        const fyOptions = rawData.map((data: any) => +data.FY)
        setFyOptions(fyOptions)
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), GetToastProps(error)))
      .finally(() => setLoading(false))
  }

  const handleClickClimateChange = () => {
    setShowClimateChangeModal(true);
    setLoadingStressTestModal(true)

    let climateChangeChartData: any[] = [];
    let climateChangeFYOptions: any[] = [];

    Promise.all(
      [ClimateScenario.CURRENT, ClimateScenario.DISORDERLY].map((climateScenario: ClimateScenario) => {
        return getOrganizationAEData({
          force_refresh: false,
          include_trajectory: true,
          climateScenario: climateScenario,
          reportPeriod: "" + reportPeriod,
          entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
        }, filterStore.entityLevel, "emissions", "sector")
          .then((data: any) => {
            let rawData = data.data;
            if ("data" in rawData) rawData = rawData.data
            rawData = rawData.sort((a: any, b: any) => +a.FY - +b.FY)

            if (climateChangeFYOptions.length === 0) {
              climateChangeFYOptions = rawData.map((data: any) => data.FY)
            }

            if (rawData.length === 0) return;

            const dataKeys = Object.keys(rawData[0])
              .filter((key: string) => !["FY"].includes(key));

            for (const key of dataKeys) {
              if (!dataKeyColours.current[key]) {
                dataKeyColours.current[key] = RandomRGB();
              }
            }

            dataKeys.forEach((key: any) => {
              climateChangeChartData.push({
                name: `${FormatKeyToLabel(key)} - ${GetScenarioName(climateScenario)}`,
                type: 'line',
                data: rawData.map((data: any) => ({
                  ...data[key],
                  fy: data.FY,
                  climateScenario: climateScenario,
                })),
                climateScenario: climateScenario,
                itemStyle: {
                  color: dataKeyColours.current[key] || RandomRGB()
                },
                isTotal: key === "Net Emissions",
              })
            })
          })
          .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), GetToastProps(error)))
      })
    ).then(() => {
      climateChangeChartData.sort((a: any, b: any) => a.name.localeCompare(b.name))
      
      setClimateChangeModalLineSeriesDataWithFy(climateChangeChartData)
      setClimateChangeModalFyOptions(climateChangeFYOptions)
    })
      .finally(() => setLoadingStressTestModal(false))
  }

  const handleExportData = () => {
    exportFile(
      GenerateExportUrl("ae", "emissions", "sector"),
      {
        force_refresh: false,
        include_trajectory: false,
        climateScenario: showClimateChangeModal ? selectedClimateScenario : ClimateScenario.CURRENT,
        reportPeriod: "" + reportPeriod,
        entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      }, "Absolute Emission - GHG Types and Sources.pdf")
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), GetToastProps(error)))
  }

  useEffect(() => {
    refreshData()
  }, [])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={handleClickClimateChange}
        changeReportPeriodCallBack={(value: number) => refreshData(value)}
        onClickExport={handleExportData}
        defaultBenchmark={false}
        defaultTarget={false}
        showExport={filterStore.entityLevel === EntityLevel.ASSET}
      />

      <SimpleBarChart
        loading={loading}
        tooltipFormatter={(value: any) => {
          // console.log(value)
          const data = value.data

          let retString = `<b>${value.seriesName}</b><br>`
          if (data.value !== 0) retString += `Value: ${(+data.value).toLocaleString()} ${unit}<br>`
          if (data.change) retString += `Change: ${ParseChangeValues(data.change).toLocaleString()}%<br>`
          if (data.benchmark) retString += `Benchmark: ${(+data.benchmark).toLocaleString()} ${unit}<br>`
          if (data.target) retString += `Target: ${(+data.target).toLocaleString()} ${unit}<br>`
          return retString
        }}
        yAxisTitle={unit}
        series={
          chartData.map((data: any) => ({
            ...data,
            data: data.data.filter((data: any) => +data.fy >= reportPeriod - compareToYears && +data.fy <= reportPeriod)
          }))
        }
        categories={
          fyOptions
            .filter((fy: number) => +fy >= reportPeriod - compareToYears && +fy <= reportPeriod)
            .map((fy: number) => FormatFYChartTitle(fy))
        }
        benchmarkValue={benchmarkValue}
        targetValue={targetValue}
        averageValue={averageValue}
        showZoom={false}
      />

      <VmModal open={showClimateChangeModal} onClose={() => setShowClimateChangeModal(false)}>
        <Box className="flex items-center justify-between">
          <Typography variant="h6">Stress Test</Typography>
          <IconButton onClick={() => setShowClimateChangeModal(false)}>
            <Close />
          </IconButton>
        </Box>
        <ClimateChangeToggleBar
          selectedClimateScenario={selectedClimateScenario}
          setSelectedClimateScenario={setSelectedClimateScenario}
          showTotalOnly={climateChangeOnlyShowTotal}
          setShowTotalOnly={setClimateChangeOnlyShowTotal}
        />
        <SimpleLineChart
          key={`climate-change-modal-line-chart-${selectedClimateScenario}-${climateChangeOnlyShowTotal}`}
          yAxisTitle={"tCO2e"}
          animation={false}
          loading={loadingStressTestModal}
          titles={climateChangeModalFyOptions.filter((fy: number) => fy >= reportPeriod).map(fy => FormatFYChartTitle(fy))}
          // minYAxisValue={GetMinYAxisValue(climateChangeModalLineSeriesDataWithFy.filter((data: any) => climateChangeOnlyShowTotal || data.isTotal === true))}
          series={climateChangeModalLineSeriesDataWithFy
            .filter((data: any) => climateChangeOnlyShowTotal || data.isTotal === true)
            .map((data: any) => ({
              ...data,
              type: "line",
              symbol: selectedClimateScenario === data.climateScenario ? 'roundRect' : 'triangle',
              symbolSize: 20,
              lineStyle: {
                width: 2,
                type: selectedClimateScenario === data.climateScenario ? "solid" : "dashed",
                color: data.color
              },
              data: data.data
                .filter((value: any) => +value.fy >= reportPeriod)
                .map((value: any) => {
                  return {
                    ...value,
                    itemStyle: {
                      color: value.color,
                      opacity: selectedClimateScenario === value.climateScenario ? 1 : 0.4,
                    },
                    value: value.value,
                  }
                })
            }))}
        />
      </VmModal>
    </>
  )
}

export default observer(SummeryAESectorSection)
