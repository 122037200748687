import { observer } from "mobx-react";
import React from "react";
import { Box, IconButton, Popover } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ITEM_PERFECT_INLINED } from "./VmTables";
import { Info } from "@mui/icons-material";

const VmHelp = observer(({ children, sx, fontSize }: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton onClick={(e: any) => setAnchorEl(e.currentTarget)} sx={sx}>
        <Info sx={{ fontSize: fontSize }} />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box className="p-4" sx={{ maxWidth: 400 }}>
          <Box className="text-gray-600 text-sm">{children}</Box>
        </Box>
      </Popover>
    </>
  );
});

export default VmHelp;