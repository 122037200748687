import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { exportFile, getOrganizationAEData, getOrganizationFEData } from '../../../api/explorerService'
import { ClimateScenario, EntityLevel, SearchType } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import ScopeCompareBarChart from '../../charts/scope-compare-bar-chart'
import SingleBarChart from '../../charts/single-bar-chart'
import CompareFilters from '../../CompareFilters'
import { APIErrorHandler, FormatFYChartTitle, FormatKeyToLabel, GenerateExportUrl, GenerateFYOptions, GetMinYAxisValue, GetScenarioName, GetToastProps } from '../../../utils/general'
import { FilterContext } from '../../../context/filter-bar-context'
import SimpleBarChart from '../../charts/simple-bar-chart'
import { IconButton, Typography } from '@mui/material'
import { Box } from '@mui/material'
import { FilterLevelThree } from '../../summary/filter-section'
import VmModal from '../../VmModal'
import ClimateChangeToggleBar from '../../climate_change_toggle_bar'
import { Close } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { FilterStoreContext } from '../../../context/filter-store-context'
import BannerText from '../../BannerText'
import { defaultDataKeyColours, RandomRGB } from '../../../utils/colour'
import SimpleLineChart from '../../charts/simple-line-chart'

const SummaryPhysicalEEIPEIWACI = observer(({ levelThree }: { levelThree: FilterLevelThree }) => {
  const { reportPeriod, compareToYears, } = useContext(FilterContext)
  const filterStore = useContext(FilterStoreContext);
  const { enqueueSnackbar } = useSnackbar();

  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])
  const [climateChangeModalChartData, setClimateChangeModalChartData] = useState<any>([])

  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const [selectedClimateScenario, setSelectedClimateScenario] = useState<ClimateScenario>(ClimateScenario.CURRENT)

  const [farmNamesList, setFarmNamesList] = useState<string[]>([])
  const [yAxisTitle, setYAxisTitle] = useState<string>("")

  const [climateChangeModalLoading, setClimateChangeModalLoading] = useState<boolean>(false);
  const [climateChangeModalFyOptions, setClimateChangeModalFyOptions] = useState<number[]>([]);
  const [climateChangeModalLineChartData, setClimateChangeModalLineChartData] = useState<any>([]);
  const [climateChangeOnlyShowTotal, setClimateChangeOnlyShowTotal] = useState<boolean>(false);

  const dataKeyColours = useRef<{ [key: string]: string }>(defaultDataKeyColours);

  const [benchmarkValue, setBenchmarkValue] = useState<number | null>(null)
  const [targetValue, setTargetValue] = useState<number | null>(null)

  const [loading, setLoading] = useState<boolean>(true)

  const levelThreeParam = levelThree === FilterLevelThree.FINANCED_PHYSICAL_EEI ? "eei"
    : levelThree === FilterLevelThree.FINANCED_PHYSICAL_PEI ? "pei"
      : levelThree === FilterLevelThree.FINANCED_PHYSICAL_WACI ? "waci"
        : null

  const refreshChartData = (fy: number = reportPeriod) => {
    if (!levelThreeParam) return;

    setLoading(true)
    getOrganizationFEData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + fy,
      entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
    }, filterStore.entityLevel, "intensity", levelThreeParam)
      .then((data) => {
        // console.log(data)
        let farmIds: string[] = []
        let farmNames: string[] = []
        let tempFySeriesData: any = [];
        data.data.forEach((fyData: any, fyIndex: number) => {
          if (fyIndex === 0) {
            farmIds = fyData.data.map((farm: any) => farm.id)
            farmNames = fyData.data.map((farm: any) => farm.name || farm.id)
            setFarmNamesList(farmNames)
            if (levelThree === FilterLevelThree.FINANCED_PHYSICAL_EEI) setYAxisTitle(fyData.data && fyData.data.length > 0 ? fyData.data[0].eei_unit : "")
            else if (levelThree === FilterLevelThree.FINANCED_PHYSICAL_PEI) setYAxisTitle(fyData.data && fyData.data.length > 0 ? fyData.data[0].pei_unit : "")
            else if (levelThree === FilterLevelThree.FINANCED_PHYSICAL_WACI) setYAxisTitle(fyData.data && fyData.data.length > 0 ? fyData.data[0].waci_unit : "")
          }

          tempFySeriesData.push({
            name: +fyData.FY,
            type: 'bar',
            barGap: 0,
            barWidth: "25%",
            emphasis: {
              focus: 'series'
            },
            data: farmIds.map((farmId: string) => {
              const farm = fyData.data.find((farm: any) => farm.id === farmId)
              if (farm) {
                if (levelThree === FilterLevelThree.FINANCED_PHYSICAL_EEI) return farm.eei
                else if (levelThree === FilterLevelThree.FINANCED_PHYSICAL_PEI) return farm.pei
                else if (levelThree === FilterLevelThree.FINANCED_PHYSICAL_WACI) return farm.waci
                else return 0
              }
              return 0
            }),
          })
        })

        // if (showClimateChangeModal) {
        //   setClimateChangeModalChartData(tempFySeriesData)
        // } else {
        setChartData(tempFySeriesData)
        // }
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), GetToastProps(error)))
      .finally(() => setLoading(false))
  }

  const handleOpenClimateChangeModal = () => {
    if (!levelThreeParam) return;
    setShowClimateChangeModal(true)
    setClimateChangeModalLoading(true)

    let climateChangeChartData: any[] = [];
    let climateChangeFYOptions: any[] = [];

    Promise.all([ClimateScenario.CURRENT, ClimateScenario.DISORDERLY].map((climateScenario: ClimateScenario) =>
      getOrganizationFEData({
        force_refresh: false,
        include_trajectory: false,
        climateScenario: climateScenario,
        reportPeriod: "" + reportPeriod,
        entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      }, filterStore.entityLevel, "intensity", levelThreeParam)
        .then((data) => {
          let rawData = data.data;
          if ("data" in rawData) rawData = rawData.data

          if (!Array.isArray(rawData)) {
            // Handle case where rawData is in wrong format
            setChartData([]);
            return;
          }
          
          rawData = rawData.sort((a: any, b: any) => +a.FY - +b.FY)

          if (climateChangeFYOptions.length === 0) {
            climateChangeFYOptions = rawData.map((data: any) => data.FY)
          }

          if (rawData.length === 0) return;

          const farmNames = rawData[0].data.map((farm: any) => farm.name)

          for (const farmName of farmNames) {
            if (!dataKeyColours.current[farmName]) {
              dataKeyColours.current[farmName] = RandomRGB();
            }
          }

          farmNames.forEach((farmName: string) => {
            climateChangeChartData.push({
              name: `${farmName} - ${GetScenarioName(climateScenario)}`,
              type: 'line',
              data: rawData.map((fyData: any) => {
                const farmData = fyData.data.find((farm: any) => farm.name === farmName)
                const value = farmData ? farmData[levelThreeParam] : 0

                return {
                  ...farmData,
                  value: value,
                  fy: fyData.FY,
                  climateScenario: climateScenario,
                }
              }),
              climateScenario: climateScenario,
              itemStyle: {
                color: dataKeyColours.current[farmName] || RandomRGB()
              },
              isTotal: false,
            })
          })
        })
    ))
      .then(() => {
        climateChangeChartData.sort((a: any, b: any) => a.name.localeCompare(b.name))

        setClimateChangeModalLineChartData(climateChangeChartData)
        setClimateChangeModalFyOptions(climateChangeFYOptions)
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), GetToastProps(error)))
      .finally(() => setClimateChangeModalLoading(false))
  }

  const handleExportData = () => {
    if (!levelThreeParam) return;

    exportFile(
      GenerateExportUrl("fe", "emissions", levelThreeParam),
      {
        force_refresh: false,
        include_trajectory: false,
        climateScenario: showClimateChangeModal ? selectedClimateScenario : ClimateScenario.CURRENT,
        reportPeriod: "" + reportPeriod,
        entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      }, "Financed Emissions - Physical.pdf")
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), GetToastProps(error)))
  }

  useEffect(() => {
    refreshChartData()
  }, [levelThree])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={handleOpenClimateChangeModal}
        changeReportPeriodCallBack={refreshChartData}
        onClickExport={handleExportData}
        showExport={filterStore.entityLevel === EntityLevel.ASSET}
      />
      <SimpleBarChart
        loading={loading}
        key={`${reportPeriod}-${compareToYears}`}
        series={chartData.filter((fySeries: any) => fySeries.name >= reportPeriod - compareToYears && fySeries.name <= reportPeriod)}
        yAxisTitle={yAxisTitle}
        categories={farmNamesList}
        benchmarkValue={benchmarkValue}
        showZoom={true}
        targetValue={targetValue}
      />

      <VmModal open={showClimateChangeModal} onClose={() => setShowClimateChangeModal(false)}>
        <Box className="flex items-center justify-between">
          <Typography variant="h6">Stress Test</Typography>
          <IconButton onClick={() => setShowClimateChangeModal(false)}>
            <Close />
          </IconButton>
        </Box>
        <ClimateChangeToggleBar selectedClimateScenario={selectedClimateScenario} setSelectedClimateScenario={setSelectedClimateScenario} />
        <SimpleLineChart
          key={`climate-change-modal-line-chart-${selectedClimateScenario}-${climateChangeOnlyShowTotal}`}
          yAxisTitle={yAxisTitle}
          animation={false}
          loading={climateChangeModalLoading}
          titles={climateChangeModalFyOptions.filter((fy: number) => fy >= reportPeriod).map(fy => FormatFYChartTitle(fy))}
        // minYAxisValue={GetMinYAxisValue(climateChangeModalLineChartData)}
          series={climateChangeModalLineChartData
            .map((data: any) => ({
              ...data,
              type: "line",
              symbol: selectedClimateScenario === data.climateScenario ? 'roundRect' : 'triangle',
              symbolSize: 20,
              lineStyle: {
                width: 2,
                type: selectedClimateScenario === data.climateScenario ? "solid" : "dashed",
                color: data.color
              },
              data: data.data
                .filter((value: any) => +value.fy >= reportPeriod)
                .map((value: any) => {
                  return {
                    ...value,
                    itemStyle: {
                      color: value.color,
                      opacity: selectedClimateScenario === value.climateScenario ? 1 : 0.4,
                    },
                    value: value.value,
                  }
                })
            }))}
        />
      </VmModal>
    </>
  )
})

export default SummaryPhysicalEEIPEIWACI
