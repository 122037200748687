import React, { useContext, useEffect, useRef, useState } from 'react'
import RegionDataTable from '../../charts/region-data-table'
import { observer } from 'mobx-react-lite'
import { exportFile, getOrganizationAEData, getOrganizationFEData } from '../../../api/explorerService'
import { ClimateScenario, EntityLevel, SearchType } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import CompareFilters from '../../CompareFilters'
import moment from 'moment'
import { APIErrorHandler, FormatFYChartTitle, FormatKeyToLabel, GenerateExportUrl, GenerateFYOptions, GetMinYAxisValue, GetScenarioName, GetToastProps } from '../../../utils/general'
import { FilterContext } from '../../../context/filter-bar-context'
import SectorCompareBarChart from '../../charts/sector-compare-bar-chart'
import { Box, IconButton, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import VmModal from '../../VmModal'
import ClimateChangeToggleBar from '../../climate_change_toggle_bar'
import { defaultDataKeyColours, lerpColor, RandomRGB } from '../../../utils/colour'
import SimpleBarChart from '../../charts/simple-bar-chart'

import { FilterStoreContext } from '../../../context/filter-store-context'
import { useSnackbar } from 'notistack'
import SimpleLineChart from '../../charts/simple-line-chart'

const SummeryFESectorSection = observer(({ }: {}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { reportPeriod, compareToYears, setAllowedCompareToYears, } = useContext(FilterContext)
  const filterStore = useContext(FilterStoreContext);
  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')

  const [chartData, setChartData] = useState<any>([])
  const [stressTestModalData, setStressTestModalData] = useState<any>([])
  const [loadingStressTestModal, setLoadingStressTestModal] = useState<boolean>(false)

  // climate change modal
  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const [selectedClimateScenario, setSelectedClimateScenario] = useState<ClimateScenario>(ClimateScenario.CURRENT)
  const [climateChangeModalFyOptions, setClimateChangeModalFyOptions] = useState<number[]>([])
  const [climateChangeModalLineSeriesDataWithFy, setClimateChangeModalLineSeriesDataWithFy] = useState<any[]>([])
  const [climateChangeOnlyShowTotal, setClimateChangeOnlyShowTotal] = useState<boolean>(false)

  const [fyOptions, setFyOptions] = useState<number[]>([])

  const [loading, setLoading] = useState<boolean>(false)
  const [climateChangeModalLoading, setClimateChangeModalLoading] = useState<boolean>(false)

  const dataKeyColours = useRef<{ [key: string]: string }>(defaultDataKeyColours)

  const refreshData = (fy: number = reportPeriod) => {
    const currentReportPeriod = +JSON.stringify(fy)

    setLoading(true)
    getOrganizationFEData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: ClimateScenario.CURRENT,
      reportPeriod: "" + currentReportPeriod,
      entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
    }, filterStore.entityLevel, "emissions", "sector")
      .then((data) => {
        let tempBarChartData: any[] = []

        const sortedData = data.data.data.sort((a: any, b: any) => +a.FY - +b.FY)

        if (sortedData.length > 0) {
          let dataKeys = Object.keys(sortedData[0]).filter((key: string) => !["FY"].includes(key))

          const selectedFYData = sortedData.find((data: any) => +data.FY === fy)

          if (selectedFYData) {
            const benchmarkValue = selectedFYData["Net Emissions"].benchmark
            const targetValue = selectedFYData["Net Emissions"].target

            const markLineData: any[] = []

            if (targetValue !== 0) markLineData.push({
              yAxis: targetValue,
              name: 'Target',
              lineStyle: {
                type: 'solid',
                color: '#D91009',
                width: 2
              },
              label: {
                show: true,
                // @ts-ignore
                formatter: () => `Target:\n${(targetValue.toFixed(2)).toLocaleString()} tCO2e`
              }
            })

            if (benchmarkValue !== 0) markLineData.push({
              yAxis: benchmarkValue,
              name: 'Benchmark',
              lineStyle: {
                type: 'solid',
                color: '#FCA400',
                width: 2
              },
              label: {
                show: true,
                // @ts-ignore
                formatter: () => `Benchmark:\n${(benchmarkValue.toFixed(2)).toLocaleString()} tCO2e`
              }
            })
          }

          dataKeys
            .filter((key: string) => !["Net Emissions", "Removals"].includes(key))
            .forEach((key: string, keyIndex: number) => {
              tempBarChartData.push({
                name: FormatKeyToLabel(key),
                type: 'bar',
                stack: 'stack1',
                barWidth: 80,
                data: sortedData.map((data: any) => ({
                  ...data[key],
                  fy: data.FY
                })),
                itemStyle: {
                  color: lerpColor("#1d62f4", "#002c8c", keyIndex / (dataKeys.length - 1))
                },
                // markLine: {
                //   data: (keyIndex === 0) ? markLineData : []
                // },
              })
            })

          tempBarChartData.push({
            name: "Carbon Removals",
            type: 'bar',
            stack: 'stack1',
            // data: data.data.data.map((data: any) => -1 * 99999),
            data: sortedData.map((data: any) => ({
              ...data["Removals"],
              value: (data["Removals"] && data["Removals"].value) ? -1 * (data["Removals"].value) : 0,
              fy: data.FY
            })),
            itemStyle: {
              color: "white",
              borderColor: "#0061ff",
              borderWidth: 1,
              borderType: "dashed"
            }
          })

          tempBarChartData.push({
            name: "Net Emissions",
            type: 'line',
            data: sortedData.map((data: any) => ({
              ...data["Net Emissions"],
              fy: data.FY
            })),
            symbol: "rect",
            symbolSize: [80, 5],
            itemStyle: {
              color: "#15F5BA"
            }
          })

          // console.log(tempBarChartData)

          if (showClimateChangeModal) {
            setStressTestModalData(tempBarChartData)
          } else {
            setChartData(tempBarChartData)
          }

          const firstFY = sortedData[0].FY
          setAllowedCompareToYears((+fy) - (+firstFY))
        }

        const fyOptions = sortedData.map((data: any) => +data.FY)
        setFyOptions(fyOptions)
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), GetToastProps(error)))
      .finally(() => setLoading(false))
  }

  const handleClickClimateChange = () => {
    setShowClimateChangeModal(true)
    setClimateChangeModalLoading(true)

    let climateChangeChartData: any[] = [];
    let climateChangeFYOptions: any[] = [];

    Promise.all([ClimateScenario.CURRENT, ClimateScenario.DISORDERLY].map((climateScenario: ClimateScenario) => {
      return getOrganizationFEData({
        force_refresh: false,
        include_trajectory: true,
        climateScenario: climateScenario,
        reportPeriod: "" + reportPeriod,
        entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      }, filterStore.entityLevel, "emissions", "sector")
        .then((data: any) => {
          let rawData = data.data;
          if ("data" in rawData) rawData = rawData.data

          if (!Array.isArray(rawData)) {
            // Handle case where rawData is in wrong format
            setChartData([]);
            return;
          }

          rawData = rawData.sort((a: any, b: any) => +a.FY - +b.FY)

          if (climateChangeFYOptions.length === 0) {
            climateChangeFYOptions = rawData.map((data: any) => data.FY)
          }

          if (rawData.length === 0) return;

          const dataKeys = Object.keys(rawData[0])
            .filter((key: string) => !["FY"].includes(key));

          for (const key of dataKeys) {
            if (!dataKeyColours.current[key]) {
              dataKeyColours.current[key] = RandomRGB();
            }
          }

          dataKeys.forEach((key: any) => {
            climateChangeChartData.push({
              name: `${FormatKeyToLabel(key)} - ${GetScenarioName(climateScenario)}`,
              type: 'line',
              data: rawData.map((data: any) => ({
                ...data[key],
                fy: data.FY,
                climateScenario: climateScenario,
              })),
              climateScenario: climateScenario,
              itemStyle: {
                color: dataKeyColours.current[key] || RandomRGB()
              },
              color: dataKeyColours.current[key] || RandomRGB(),
              isTotal: key === "Net Emissions",
            })
          })
        })
    })).then(() => {
      climateChangeChartData.sort((a: any, b: any) => a.name.localeCompare(b.name))
      
      setClimateChangeModalLineSeriesDataWithFy(climateChangeChartData)
      setClimateChangeModalFyOptions(climateChangeFYOptions)
    })
      .finally(() => setClimateChangeModalLoading(false))
  }

  const handleExportData = () => {
    exportFile(
      GenerateExportUrl("fe", "emissions", "sector"),
      {
        force_refresh: false,
        include_trajectory: false,
        climateScenario: showClimateChangeModal ? selectedClimateScenario : ClimateScenario.CURRENT,
        reportPeriod: "" + reportPeriod,
        entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      }, "Financed Emissions - Sector.pdf")
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), GetToastProps(error)))
  }

  useEffect(() => {
    refreshData()
  }, [])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={handleClickClimateChange}
        changeReportPeriodCallBack={(value: number) => refreshData(value)}
        onClickExport={handleExportData}
        showExport={filterStore.entityLevel === EntityLevel.ASSET}
      />
      <SimpleBarChart
        loading={loading}
        series={
          chartData.map((data: any) => ({
            ...data,
            data: data.data.filter((data: any) => +data.fy >= reportPeriod - compareToYears && +data.fy <= reportPeriod)
          }))
        }
        categories={
          fyOptions
            .filter((fy: number) => +fy >= reportPeriod - compareToYears && +fy <= reportPeriod)
            .map((fy: number) => fy.toString())
        }
        showZoom={false}
      />
      {/* : <SectorCompareBarChart data={chartData.filter((data: any) => +data.FY >= reportPeriod - compareToYears)} /> */}

      <VmModal open={showClimateChangeModal} onClose={() => setShowClimateChangeModal(false)}>
        <Box className="flex items-center justify-between">
          <Typography variant="h6">Stress Test</Typography>
          <IconButton onClick={() => setShowClimateChangeModal(false)}>
            <Close />
          </IconButton>
        </Box>
        <ClimateChangeToggleBar
          selectedClimateScenario={selectedClimateScenario}
          setSelectedClimateScenario={setSelectedClimateScenario}
          showTotalOnly={climateChangeOnlyShowTotal}
          setShowTotalOnly={setClimateChangeOnlyShowTotal}
        />
        <SimpleLineChart
          key={`climate-change-modal-line-chart-${selectedClimateScenario}-${climateChangeOnlyShowTotal}`}
          yAxisTitle={"tCO2e"}
          animation={false}
          loading={climateChangeModalLoading}
          titles={climateChangeModalFyOptions.filter((fy: number) => fy >= reportPeriod).map(fy => FormatFYChartTitle(fy))}
          // minYAxisValue={GetMinYAxisValue(climateChangeModalLineSeriesDataWithFy.filter((data: any) => climateChangeOnlyShowTotal || data.isTotal === true))}
          series={climateChangeModalLineSeriesDataWithFy
            .filter((data: any) => climateChangeOnlyShowTotal || data.isTotal === true)
            .map((data: any) => ({
              ...data,
              type: "line",
              symbol: selectedClimateScenario === data.climateScenario ? 'roundRect' : 'triangle',
              symbolSize: 20,
              lineStyle: {
                width: 2,
                type: selectedClimateScenario === data.climateScenario ? "solid" : "dashed",
                color: data.color
              },
              data: data.data
                .filter((value: any) => +value.fy >= reportPeriod)
                .map((value: any) => {
                  return {
                    ...value,
                    itemStyle: {
                      color: data.color,
                      opacity: selectedClimateScenario === value.climateScenario ? 1 : 0.4,
                    },
                    value: value.value,
                  }
                })
            }))}
        />
      </VmModal>
    </>
  )
})

export default SummeryFESectorSection
