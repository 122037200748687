import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { exportFile, getOrganizationAEData } from '../../../api/explorerService'
import { ClimateScenario, EntityLevel, SearchType } from '../../../utils/options'
import SecureStorage from '../../../services/secureStorage'
import { APIErrorHandler, FormatFYChartTitle, FormatKeyToLabel, GenerateExportUrl, GenerateFYOptions, GetMinYAxisValue, GetScenarioName, GetToastProps } from '../../../utils/general'
import CompareFilters from '../../CompareFilters'
import { FilterContext } from '../../../context/filter-bar-context'
import RegionDataTable from '../../charts/region-data-table'
import { IconButton } from '@mui/material'
import { Box, Typography } from '@mui/material'
import ClimateChangeToggleBar from '../../climate_change_toggle_bar'
import VmModal from '../../VmModal'
import { Close, Timeline, List } from '@mui/icons-material'
import { defaultDataKeyColours, lerpColor, RandomRGB } from '../../../utils/colour'
import SimpleLineChart from '../../charts/simple-line-chart'
import { FilterStoreContext } from '../../../context/filter-store-context'
import BannerText from '../../BannerText'
import { useSnackbar } from 'notistack'

const SummeryAERegionSection = observer(({ }: {}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { reportPeriod, compareToYears } = useContext(FilterContext)
  const filterStore = useContext(FilterStoreContext);

  const [organizationId, setOrganizationId] = useState<string>(SecureStorage.getInstance().getItem('organizationId') || '')
  const [chartData, setChartData] = useState<any>([])
  const [lineChartData, setLineChartData] = useState<any>([])

  const [dataKeys, setDataKeys] = useState<any>([])
  const [fyOptions, setFyOptions] = useState<number[]>([])
  const [climateChangeModalLineChartData, setClimateChangeModalLineChartData] = useState<any>([])
  const [climateChangeModalDataKeys, setClimateChangeModalDataKeys] = useState<any>([])

  const [modeToggleSelected, setModeToggleSelected] = useState<string>("Table")

  // climate change modal
  const [showClimateChangeModal, setShowClimateChangeModal] = useState<boolean>(false)
  const [selectedClimateScenario, setSelectedClimateScenario] = useState<ClimateScenario>(ClimateScenario.CURRENT)
  const [climateChangeModalFyOptions, setClimateChangeModalFyOptions] = useState<number[]>([])
  const [climateChangeModalLineSeriesDataWithFy, setClimateChangeModalLineSeriesDataWithFy] = useState<any[]>([])
  const [climateChangeOnlyShowTotal, setClimateChangeOnlyShowTotal] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)
  const [climateChangeModalLoading, setClimateChangeModalLoading] = useState<boolean>(false)

  const dataKeyColours = useRef<{ [key: string]: string }>(defaultDataKeyColours)

  const refreshData = (fy: number = reportPeriod) => {
    setLoading(true)
    getOrganizationAEData({
      force_refresh: false,
      include_trajectory: false,
      climateScenario: showClimateChangeModal ? selectedClimateScenario : ClimateScenario.CURRENT,
      reportPeriod: "" + fy,
      entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
    }, filterStore.entityLevel, "emissions", "region")
      .then((data) => {
        let rawData = data.data;
        if (rawData.data) rawData = rawData.data;

        if (!Array.isArray(rawData)) {
          // Handle case where rawData is in wrong format
          setChartData([]);
          return;
        }

        const chartData = rawData.sort((a: any, b: any) => (+a.FY) - (+b.FY));

        const dataKeys = Object.keys(chartData[0])
          .filter((key) => !["FY"].includes(key))
          .sort((a: string, b: string) => a.localeCompare(b))
          .map((key) => ({ key, title: key }))

        let tempLineChartData: any[] = []

        if (rawData.length > 0) {
          let dataKeys = Object.keys(rawData[0]).filter((key: string) => !["FY"].includes(key))

          dataKeys
            .filter((key: string) => !["NetEmissions", "CarbonRemovals"].includes(key))
            .forEach((key: string, keyIndex: number) => {
              const rawData1 = rawData.map((data: any) => ({
                ...data[key],
                fy: +data.FY
              }))
              tempLineChartData.push({
                name: key,
                // name: FormatKeyToLabel(key),
                type: 'line',
                data: rawData1,
                symbol: "roundRect",
                symbolSize: 16,
                itemStyle: {
                  color: lerpColor("#1d62f4", "#002c8c", keyIndex / (dataKeys.length - 1))
                },
              })
            })
        }

        setFyOptions(rawData.map((data: any) => data.FY))
        setLineChartData(tempLineChartData)
        setChartData(chartData)
        setDataKeys(dataKeys)
      })
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), GetToastProps(error)))
      .finally(() => setLoading(false))
  }

  const handleClickClimateChange = () => {
    setShowClimateChangeModal(true);
    setClimateChangeModalLoading(true)

    let climateChangeChartData: any[] = [];
    let climateChangeFYOptions: any[] = [];

    Promise.all(
      [ClimateScenario.CURRENT, ClimateScenario.DISORDERLY].map((climateScenario: ClimateScenario) => {
        return getOrganizationAEData({
          force_refresh: false,
          include_trajectory: true,
          climateScenario: showClimateChangeModal ? selectedClimateScenario : ClimateScenario.CURRENT,
          reportPeriod: "" + reportPeriod,
          entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
        }, filterStore.entityLevel, "emissions", "region")
          .then((data: any) => {
            let rawData = data.data;
            if ("data" in rawData) rawData = rawData.data
            rawData = rawData.sort((a: any, b: any) => +a.FY - +b.FY)

            if (climateChangeFYOptions.length === 0) {
              climateChangeFYOptions = rawData.map((data: any) => data.FY)
            }

            if (rawData.length === 0) return;
            
            const dataKeys = Object.keys(rawData[0])
              .filter((key: string) => !["FY"].includes(key));

            for (const key of dataKeys) {
              if (!dataKeyColours.current[key]) {
                dataKeyColours.current[key] = RandomRGB();
              }
            }

            dataKeys.forEach((key: any) => {
              climateChangeChartData.push({
                name: `${FormatKeyToLabel(key)} - ${GetScenarioName(climateScenario)}`,
                type: 'line',
                data: rawData.map((data: any) => ({
                  ...data[key],
                  fy: data.FY,
                  climateScenario: climateScenario,
                })),
                climateScenario: climateScenario,
                itemStyle: {
                  color: dataKeyColours.current[key] || RandomRGB()
                },
                isTotal: key.toLowerCase() === "total",
              })
            })
          })
          .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), GetToastProps(error)))
      })
    ).then(() => {
      climateChangeChartData.sort((a: any, b: any) => a.name.localeCompare(b.name))
      
      setClimateChangeModalLineSeriesDataWithFy(climateChangeChartData)
      setClimateChangeModalFyOptions(climateChangeFYOptions)
    })
      .finally(() => setClimateChangeModalLoading(false))
  }

  const handleExportData = () => {
    exportFile(
      GenerateExportUrl("ae", "emissions", "region"),
      {
        force_refresh: false,
        include_trajectory: false,
        climateScenario: showClimateChangeModal ? selectedClimateScenario : ClimateScenario.CURRENT,
        reportPeriod: "" + reportPeriod,
        entityId: filterStore.entityIds.assetId?.id || filterStore.entityIds.assetOwnerId?.id || organizationId,
      }, "Absolute Emission - Region.pdf")
      .catch((error: any) => enqueueSnackbar(APIErrorHandler(error), GetToastProps(error)))
  }

  useEffect(() => {
    refreshData()
  }, [])

  return (
    <>
      <CompareFilters
        handleClickClimateChange={handleClickClimateChange}
        changeReportPeriodCallBack={refreshData}
        modeToggleButtonOptions={[{ label: "Table", icon: <List /> }, { label: "Series", icon: <Timeline /> }]}
        modeToggleSelected={modeToggleSelected}
        onClickExport={handleExportData}
        showExport={filterStore.entityLevel === EntityLevel.ASSET}
        onModeToggleButtonClick={(value: string) => setModeToggleSelected(value)}
      />

      {
        loading
          ? <BannerText text={"Loading..."} />
          : modeToggleSelected === "Table" ? <>
            <RegionDataTable
              tableData={chartData.filter((data: any) => +data.FY >= reportPeriod - compareToYears && +data.FY <= reportPeriod)}
              dataKeys={dataKeys}
              unitCellContent={<p className="opacity-40">tCO2e</p>}
            />
          </>
            : <SimpleLineChart series={
              lineChartData.map((data: any) => ({
                ...data,
                data: data.data.filter((data: any) => +data.fy >= reportPeriod - compareToYears && +data.fy <= reportPeriod)
              }))
            }
              titles={
                fyOptions
                  .filter((fy: number) => +fy >= reportPeriod - compareToYears && +fy <= reportPeriod)
                  .map((fy: number) => FormatFYChartTitle(fy))
              } />
      }

      <VmModal open={showClimateChangeModal} onClose={() => setShowClimateChangeModal(false)}>
        <Box className="flex items-center justify-between">
          <Typography variant="h6">Stress Test</Typography>
          <IconButton onClick={() => setShowClimateChangeModal(false)}>
            <Close />
          </IconButton>
        </Box>
        <ClimateChangeToggleBar selectedClimateScenario={selectedClimateScenario} setSelectedClimateScenario={setSelectedClimateScenario} />
        <SimpleLineChart
          key={`climate-change-modal-line-chart-${selectedClimateScenario}-${climateChangeOnlyShowTotal}`}
          yAxisTitle={"tCO2e"}
          animation={false}
          loading={climateChangeModalLoading}
          titles={climateChangeModalFyOptions.filter((fy: number) => fy >= reportPeriod).map(fy => FormatFYChartTitle(fy))}
          // minYAxisValue={GetMinYAxisValue(climateChangeModalLineSeriesDataWithFy)}
          series={climateChangeModalLineSeriesDataWithFy
            .map((data: any) => ({
              ...data,
              type: "line",
              symbol: selectedClimateScenario === data.climateScenario ? 'roundRect' : 'triangle',
              symbolSize: 20,
              lineStyle: {
                width: 2,
                type: selectedClimateScenario === data.climateScenario ? "solid" : "dashed",
                color: data.color
              },
              data: data.data
                .filter((value: any) => +value.fy >= reportPeriod)
                .map((value: any) => {
                  return {
                    ...value,
                    itemStyle: {
                      color: value.color,
                      opacity: selectedClimateScenario === value.climateScenario ? 1 : 0.4,
                    },
                    value: value.value,
                  }
                })
            }))}
        />
      </VmModal>
    </>
  )
})

export default SummeryAERegionSection
