import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Typography, Box, Paper, Avatar, ToggleButton, ToggleButtonGroup, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CustomButton from '../components/Button';
import { useAuth } from '../hooks/useAuth';
import { forgotPassword, triggerSignInWithEmailLink } from '../api/accountService';
import SecureStorage from '../services/secureStorage';
import { kLoggingInEmail } from '../config/constants';
import VmModal from '../components/VmModal';
import { Close, LockReset } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { GetToastProps } from '../utils/general';

const ForgotPassword: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState<string | null>(null); // Error state for validation and API errors
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [openTermsDialog, setOpenTermsDialog] = useState(false);
    const { login } = useAuth(); // Use the useAuth hook
    const navigate = useNavigate();

    const handleForgotPassword = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError(null); // Clear any previous errors
        localStorage.clear();

        try {
            await forgotPassword(username);
            enqueueSnackbar('Reset link sent to your email', { variant: "success" })
        } catch (err) {
            enqueueSnackbar('Failed to send reset link. Please try again.', GetToastProps(err))
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Container component="main" maxWidth="xs">
                <Paper elevation={3} sx={{ padding: 3, marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockReset />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Reset Password
                    </Typography>

                    {/* Display any error */}
                    {error && (
                        <Typography variant="body2" color="error" sx={{ mb: 2 }}>
                            {error}
                        </Typography>
                    )}

                    {/* Form section */}
                    <Box component="form" onSubmit={handleForgotPassword} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            disabled={loading}
                        />
                        {/* <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={loading}
                        /> */}
                        <CustomButton
                            type="submit"
                            fullWidth
                            label={loading ? 'Sending reset link...' : 'Send Reset Link'}
                            disabled={loading || !username}
                            sx={{ mt: 3, mb: 2 }}
                        />
                    </Box>
                </Paper>
            </Container>

        </>
    );

};

export default ForgotPassword;
